<template>
    <div class="vehicle-fuel-container">
        <modal-new-venhicle-fuel-redesign @confirm="confirmModalFuel" inputId="modal-new-fuel" :transportVehicle="transportVehicle" :vehicleFuel="editedFuel" :dateRange="dateRange" />
        <modal-confirm @confirm="confirmFunction" inputId="confirmModal" :title="confirmTitle" :message="confirmMessage"/>

        <div>
            <div class="btn btn-primary" @click="openAddFuelModal" data-bs-toggle="modal" data-bs-target="#modal-new-fuel">Sipanje goriva</div>
            <div class="col-md-4">
                <ejs-daterangepicker 
                    placeholder="Period"
                    v-model="dateRange"
                    :format="dateFormat"
                    :openOnFocus='true'
                    :change='rangeChanged'
                    :firstDayOfWeek=1
                    :serverTimezoneOffset=7.0
                    />
            </div>
        </div>
        <div>
            <ejs-grid
                ref="vehicleFuelGrid"
                :dataSource="vehicleFuels" 
                class="vehicle-fuel-grid"
                :allowFiltering='true'
                :allowSorting='true'
                :showColumnMenu='true'
                :filterSettings='filterSettings'
                :allowPaging='true' 
                :pageSettings='pageSettings'
                :toolbar='toolbar'
                :toolbarClick='clickHandler'
                :recordDoubleClick="absenceDBClick"
                :enablePersistence='false'
                :allowResizing='true'
                >
                <e-columns>
                    <e-column field='' headerText='#' :template="rowIndexTemplate" :width='50' textAlign='Right' :visible='true' :showColumnMenu="false"></e-column>
                    <e-column field='id' headerText='ID' width='50' textAlign='Left' :isPrimaryKey='true' :visible='false'  :allowFiltering="false" :showColumnMenu="false"></e-column>
                    <e-column field='tanked_at' :valueAccessor='dateTimeAccessor' headerText='Vreme sipanja' width='150' textAlign="Left" :visible="true"></e-column>
                    <e-column field='total_distance' :valueAccessor='numberCommaAccessor' headerText='Kilometraža' width='150' textAlign="Right"></e-column>
                    <e-column field='liters' :valueAccessor='numberCommaAccessor' headerText='Litara' width='120' textAlign="Right"></e-column>
                    <e-column field='total' :valueAccessor='numberCommaAccessor' headerText='Ukupna cena' width='150' textAlign="Right"></e-column>
                    <e-column field='distance' headerText='Prešaо' width='130' :valueAccessor='accessorSufix' textAlign="Right"></e-column>
                    <e-column field='avg_consumption' :valueAccessor='numberCommaAccessor' headerText='Potrosnja 100km' width='150' textAlign="Right"></e-column>
                    <e-column field='km_price' :valueAccessor='numberCommaAccessor' headerText='Cena 1km' width='150' textAlign="Right"></e-column>
                    <e-column field='pump' headerText='Naziv pumpe' width='140' textAlign="Left"></e-column>
                    <e-column field='payment_option' headerText='Plaćanje' width='120' textAlign="Left" :valueAccessor="payOptAccessor"></e-column>
                    <e-column field='fuel_type' headerText='Gorivo' width='115' textAlign="Left" :valueAccessor="fuelTypeAccessor"></e-column>
                    <e-column field='driver_obj.username' headerText='Vozač' width='120' textAlign="Left"></e-column>
                    <e-column field='description' headerText='Komentar' clipMode='EllipsisWithTooltip' width='200' allowFiltering='false' allowSorting='false' filterSettings='false'></e-column>
                    <e-column field='receipt_id' headerText='Broj racuna' clipMode='EllipsisWithTooltip' width='200' allowFiltering='false' allowSorting='false' filterSettings='false' :visible="false"></e-column>
                    <e-column field='created_at' :valueAccessor='dateTimeAccessor' headerText='Vreme dodavanja' width='150' textAlign="Left" :visible="true"></e-column>
                    <e-column field='created_by' headerText='Napravio zahtev' width='130' textAlign="Left" :visible="false"></e-column>
                    <e-column field='modified_by' headerText='Izmenio zahtev' width='130' textAlign="Left" :visible="false"></e-column>
                    <e-column field='updated_at' :valueAccessor='dateTimeAccessor' headerText='Vreme izmene' width='130' textAlign="Left" :visible="false"></e-column>
                </e-columns>

                <e-aggregates>
                    <e-aggregate>
                        <e-columns>
                            <e-column type="Sum" field="liters"></e-column>
                            <e-column type="Sum" field="total"></e-column>
                            <e-column type="Sum" field="distance"></e-column>
                            <e-column type="Custom" field="distance" :customAggregate="customAggregateAverageDistance"></e-column>
                            <e-column type="Custom" field="km_price" :customAggregate="customAggregateAverageKmPrice"></e-column>
                            <e-column type="Custom" field="avg_consumption" :customAggregate="customAggregateAverageConsumption"></e-column>
                        </e-columns>
                    </e-aggregate>
                </e-aggregates>
            </ejs-grid> 
        </div>
    </div>
</template>

<script>
    import ModalNewVenhicleFuelRedesign from '@/components/modals/transport/ModalNewVenhicleFuelRedesign.vue';
    import TransportService from '@/service/TransportService.js'
    import ModalConfirm from '@/components/modals/ModalConfirm.vue';

    import {mapState} from 'vuex'
    import { Modal } from 'bootstrap';

    import { Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit, Resize, Aggregate } from "@syncfusion/ej2-vue-grids";
    import UtilService from '@/service/UtilService.js';
    import RowIndexTemplate from '@/components/grid-templates/RowIndex.vue';

    export default {
        components: {ModalNewVenhicleFuelRedesign, ModalConfirm,},

        provide: {
            grid: [Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit, Resize, Aggregate ]
        },
        
        props: {
            transportVehicle: {
                type: Object,
                default: null,
            }
        },

        data() {
            return {

                filterSettings: { type: "Excel" },
                pageSettings: { pageCount: 5, pageSize: 20},
                editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true },
                toolbarAdmin: [{ text: 'Dodaj', tooltipText: 'Dodaj', prefixIcon: 'e-add', id: 'addFuel' }, { text: 'Izmeni', tooltipText: 'Izmeni', prefixIcon: 'e-edit', id: 'editFuel'}, { text: 'Obriši', tooltipText: 'Obriši', prefixIcon: 'e-delete', id: 'deleteFuel'}],
                toolbarEmp: [{ text: 'Dodaj', tooltipText: 'Dodaj', prefixIcon: 'e-add', id: 'addFuel' }],


                editedFuel: null,
                // vehicleFuels: null,
                fuelModal: null,

                confirmModal: null,
                confirmFunction: null,
                confirmTitle: "Potvrda brisanja naloga za gorivo",
                confirmMessage: "Da li ste sigurni da želite da obrišete informacije o gorivu",


                rowIndexTemplate() {
                        return {
                            template: {
                                extends: RowIndexTemplate,
                            }
                        }

                },


                dateRange: [
                    UtilService.getMoment().add(-90, 'day').toDate(),
                    UtilService.getMoment().toDate()
                ],
                dateFormat: "dd.MM.yyyy.",

            }
        },

        computed: {
            ...mapState([
                'user',
                'vehicleFuels'
            ]),

            toolbar() {
                if (this.user.role == "ADMIN") return this.toolbarAdmin;
                return null;
            }
        },

        methods: {
            translateFuelType: UtilService.translateFuelType,
            translatePayOpt: UtilService.translatePayOpt,
            formatDateTime : UtilService.formatDateTime,

            dateTimeAccessor(field, data) {
                return data[field] ? this.formatDateTime(data[field]) : '/';
            },

            numberCommaAccessor(field, data) {
                const valueAccessor = data[field]
                if (isNaN(valueAccessor)) return valueAccessor;

                return valueAccessor ? this.numberWithCommas(valueAccessor) + " " + this.getSufix(field)  : '/';
            },

            numberWithCommas(x) {
                return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            },

            accessorSufix(field, data) {
                const fieldValue = data[field]
                if (isNaN(fieldValue)) return fieldValue;

                return fieldValue + " " + this.getSufix(field)
            },

            getSufix(field) {
                if (field == 'total_distance') return 'km';
                if (field == 'distance') return 'km';
                if (field == 'liters') return 'l';
                if (field == 'total') return 'RSD';
                if (field == 'avg_consumption') return 'l/100km';
                if (field == 'km_price') return 'RSD';
                return "";
            },

            confirmModalFuel() {
                this.fuelModal.hide();
            },

            fuelTypeAccessor(field, data) {
                if (field == "key") return this.translateFuelType(data.key);

                return this.translateFuelType(data['fuel_type']);
            },

            payOptAccessor(field, data) {
                if (field == "key") return this.translatePayOpt(data.key);

                return this.translatePayOpt(data['payment_option']);
            },

            customAggregateAverageDistance(data) {
                return this.numberWithCommas(this.customAggregateAverage(data, 'distance')) + " " + this.getSufix('distance');
            },

            customAggregateAverageConsumption(data) {
                return this.customAggregateAverage(data, 'avg_consumption') + " " + this.getSufix('avg_consumption');
            },

            customAggregateAverageKmPrice(data) {
                return this.customAggregateAverage(data, 'km_price') + " " + this.getSufix('km_price');
            },

            openAddFuelModal() {
                this.editedFuel = null;   
            },

            customAggregateAverage(data, field) {
                let sum = 0;
                let notNull = 0;
                if (!Array.isArray(data))
                    data = data.result;

                data.forEach(element => {
                    if (isNaN(element[field])) return;
                    sum += element[field]
                    if (element[field] != null) notNull += 1;
                });

                if (notNull > 0) return (sum/notNull).toFixed(2);
            },

            async loadFuel() {
                let start = UtilService.dateToUTC(this.dateRange[0]).toISOString().slice(0,10);
                let end = UtilService.dateToUTC(this.dateRange[1]).toISOString().slice(0,10);

                let data = {
                    vehicle: this.transportVehicle.id,

                    params: {
                        start_date: start,
                        end_date: end,
                    }
                }


                this.$store.dispatch('loadAllVehicleFuel', data);

            },

            rangeChanged() {
                this.loadFuel();
            },

            absenceDBClick(event) {
                if (this.user.role === 'ADMIN')
                    this.editFuel(event.rowData);
                //console.log(event.rowData)
            },


            showAddFuelModal() {
                this.fuelModal.show();
            },

            async deleteFuel() {

                let data = {
                    id: this.editedFuel.id,
                }
                await TransportService.deleteVehicleFuel(data).then(() => {
                    this.loadFuel();
                }).catch((error) => {
                    console.log(error);
                })
            },

            editFuel(selected) {
                console.log(selected);
                    this.editedFuel = selected;

                    this.showAddFuelModal();
            },

            clickHandler(args) {
                if (args.item.id === 'addFuel') {
                    this.showAddFuelModal()
                }

                if (args.item.id === "editFuel"){
                    let selected = (this.$refs.vehicleFuelGrid.ej2Instances.getSelectedRecords());
                    if (selected.length > 0) selected = selected[0];
                    else return;

                    this.editFuel(selected);
                }

                if (args.item.id === "deleteFuel"){
                    let selected = (this.$refs.vehicleFuelGrid.ej2Instances.getSelectedRecords());
                    if (selected.length > 0) selected = selected[0];
                    else return;
                    this.editedFuel = selected;

                    this.confirmModal.show();
                }
            },

        },



        created() {
            this.loadFuel()
            this.confirmFunction = this.deleteFuel;
        },

        mounted() {
            this.fuelModal = new Modal(document.getElementById('modal-new-fuel'));
            this.confirmModal = new Modal(document.getElementById('confirmModal'));
        },
    }

</script>

<style>

</style>