<template>

        <div class="modal fade" :id="inputId" tabindex="-1" :aria-labelledby="inputId + 'Label'" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                        Servis vozila
                        </h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">

                        <form @submit.prevent="" id="new-vehicle-service-form">
                            <div class="mb-3 form-floating" v-if="vehicleService">
                                <input type="text" class="form-control" id="tId" disabled v-model="vehicleService.id" placeholder="Id servisa">
                                <label for="tId">Id servisa</label>
                            </div>

                            <div class="mb-3 form-floating">
                                <input type="text" class="form-control" id="field-name" v-model="vehicleServiceForm.name" placeholder="Naziv" required>
                                <label for="field-name">Naziv</label>
                                <div class="invalid-feedback">
                                    Naziv je obavezno polje.
                                </div>
                            </div>
                            
                            <div class="mb-3 form-floating">
                                <input type="text" class="form-control" id="field-service-name" v-model="vehicleServiceForm.service_name" placeholder="Ime servisa" required>
                                <label for="field-service-name">Ime servisa</label>
                                <div class="invalid-feedback">
                                    Ime servisa je obavezno polje.
                                </div>
                            </div>

                            <!-- <div class="mb-3 form-floating">
                                <input type="number" step=".01" class="form-control" id="fiel-total" v-model="vehicleServiceForm.total" placeholder="Ukupna cena" required>
                                <label for="fiel-total">Ukupna cena</label>
                                <div class="invalid-feedback">
                                    Ukupna cena je obavezno polje.
                                </div>
                            </div> -->
                            
                            <div class="mb-3 form-floating">
                                <input type="number" class="form-control" id="fiel-total-distance" v-model="vehicleServiceForm.total_distance" placeholder="Trenutna kilometraža" required>
                                <label for="fiel-liters">Trenutna kilometraža</label>
                                <div class="invalid-feedback">
                                    Trenutna kilometraža.
                                </div>
                            </div>


                            <div class="items p-2 border mb-5">
                                <div class="row">
                                    <div class="col-6"><h6 class="text-center">Stavke</h6></div>
                                    <div class="col-6"><h6 class="text-center">Cena</h6></div>
                                </div>


                                <template v-for="item,itemIndex in vehicleServiceForm.items_data">
                                    <div class="row" :key="itemIndex">
                                        <div class="col-6">
                                            <input list="serviceItems" type="text" class="form-control" v-model="vehicleServiceForm.items_data[itemIndex].name" placeholder="Stavka" required>                                        
                                        </div>
                                        <div class="d-flex col-6">
                                                <input type="number" class="form-control" step=".01" v-model="vehicleServiceForm.items_data[itemIndex].price" placeholder="Cena" required>                                        
                                                <button class="btn" @click="deleteItemRow(itemIndex)"><font-awesome-icon icon="times" /></button>
                                        </div>
                                    </div>
                                </template>

                                <div class="d-grid">
                                    <button class="btn btn-light mt-1 text-center" @click="addItemRow"><font-awesome-icon icon="plus-circle" size="2x" /></button>
                                </div>

                                <h5>Ukupna cena: {{totalPrice}}</h5>
                            </div>


                            <div class="mb-3 form-floating">
                                <textarea type="text" class="form-control" id="fiel-description" v-model="vehicleServiceForm.description" placeholder="Komentar" />
                                <label for="fiel-description">Komentar</label>
                            </div>
                            
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Izlaz</button>
                        <button type="button" class="btn btn-primary" @click="createVehicleService" >{{vehicleService?'Izmeni':'Dodaj'}}</button>
                        
                    </div>
                </div>
            </div>

            <datalist id="serviceItems">
                <option v-for="itemName, index in serviceItemNames" :key="index" :value="itemName" />
            </datalist>
        </div>
</template>

<script>
    import {mapState} from 'vuex'
    import TransportService from '@/service/TransportService.js'

    export default {

        props: {
            inputId: {
                type: String,
                default: "",
            },

            vehicleService: {
                type: Object,
                default: null,
            },

            transportVehicle: {
                type: Object,
                default: null,
            }
        },

        data() {
            return {
                vehicleServiceForm: {}
            }
        },
        
        computed: {
            ...mapState([
                'user',
                'serviceItemNames'
            ]),
            
            totalPrice() {
                let total = 0.0;

                this.vehicleServiceForm.items_data.forEach(element => {
                    if (parseFloat(element.price))
                        total += parseFloat(element.price)
                });

                return total;
            }
        },


        watch:{
            vehicleService(){
                this.updateProp();
            }
        },

        created() {
            this.updateProp()
        },

        methods: {
            updateProp() {
                if (this.vehicleService) {
                    this.vehicleServiceForm.id = this.vehicleService.id;
                    this.vehicleServiceForm.name = this.vehicleService.name;
                    this.vehicleServiceForm.service_name = this.vehicleService.service_name;
                    this.vehicleServiceForm.total = this.vehicleService.total;
                    this.vehicleServiceForm.total_distance = this.vehicleService.total_distance;
                    this.vehicleServiceForm.vehicle = this.transportVehicle.id;
                    this.vehicleServiceForm.items_data = this.vehicleService.items_obj;
                    this.vehicleServiceForm.description = this.vehicleService.description;
                } else {
                    this.vehicleServiceForm.vehicle = this.transportVehicle.id;

                    this.$set(this.vehicleServiceForm, 'items_data', [{name: '', price: undefined}]);
                }
            },

            addItemRow() {
                this.vehicleServiceForm.items_data.push({name: '', price: undefined});
            },

            deleteItemRow(index) {
                console.log(this.vehicleServiceForm.items_data[index]);
                if (this.vehicleServiceForm.items_data[index]?.name && !confirm("Da li ste sigurni da zelite da obrišete stavku: " + this.vehicleServiceForm.items_data[index]?.name)) return;

                this.vehicleServiceForm.items_data.splice(index, 1)
            },

            async createVehicleService() {
                let form = document.getElementById("new-vehicle-service-form");
                
                form.classList.add('was-validated');
                if (!form.checkValidity())
                    return;

                if (this.vehicleService) {
                    await this.updateService();
                }
                else {
                    await this.$store.dispatch('createVehicleService', this.vehicleServiceForm)
                    .then(() => {

                        this.toast("Uspešno dodavanje servisa.");
                        this.$emit('confirm');

                    })
                    .catch((error) => alert(error.message));    
                }

                this.$store.dispatch('loadAllVehicleService', {vehicle: this.transportVehicle.id});

            },

            async updateService() {
                    await TransportService.updateVehicleService(this.vehicleServiceForm)
                    .then(() => {
                        this.toast("Uspešna izmena.");
                        this.$emit('confirm');
                    })
                    .catch((error) => alert(error.message))
            },

            toast(message, type) {
                this.$toasted.show(message, { 
                    type: type,
                    theme: "toasted-primary", 
                    position: "top-center", 
                    duration : 3000
                });
            }
        },

    }
</script>

<style>

</style>