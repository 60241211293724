<template>
    <div>
        <navbar />
        <breadcrumbs></breadcrumbs>
        
        <div class="container">
        <transport-vehicle />
        </div>

    </div>
    
</template>

<script>

import Navbar from '@/components/Navbar.vue'
import TransportVehicle from '@/components/TransportVehicle.vue'

export default {
    components: {
        Navbar,
        TransportVehicle,
    },

}
</script>

<style>

</style>