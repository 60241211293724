<template>
    <div class="vehicle-service-container">
        <modal-new-venhicle-service @confirm="confirmModalService" inputId="modal-new-service" :transportVehicle="transportVehicle" :vehicleService="editedService"  />
        <modal-confirm @confirm="confirmFunction" inputId="confirmModalService" :title="confirmTitle" :message="confirmMessage"/>

        <div class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal-new-service">Unos servisa</div>
        <div>
            <ejs-grid
                ref="vehicleServiceGrid"
                :dataSource="vehicleService" 
                class="vehicle-service-grid"
                :allowFiltering='true'
                :allowSorting='true'
                :showColumnMenu='true'
                :filterSettings='filterSettings'
                :allowPaging='true' 
                :pageSettings='pageSettings'
                :toolbar='toolbar'
                :toolbarClick='clickHandler'
                :recordDoubleClick="absenceDBClick"
                :enablePersistence='false'
                :allowResizing='true'
                :childGrid="childItemsGrid"
                :dataBound='dataBound'
                >
                <e-columns>
                    <e-column field='' headerText='#' :template="rowIndexTemplate" :width='50' textAlign='Right' :visible='true' :showColumnMenu="false"></e-column>

                    <e-column field='id' headerText='ID' width='' textAlign='Left' :isPrimaryKey='true' :visible='false' :allowFiltering="false" :showColumnMenu="false"></e-column>
                    <e-column field='created_at' :valueAccessor='dateTimeAccessor' headerText='Vreme kreiranja' width='' textAlign="Left" :visible="true"></e-column>
                    <e-column field='name' headerText='Naziv' width='' textAlign="Left"></e-column>
                    <e-column field='service_name' headerText='Ime servisa' width='' textAlign="Left"></e-column>
                    <e-column field='total_price' headerText='Ukupna cena' width='' textAlign="Right"></e-column>
                    <e-column field='total_distance' headerText='Kilometraža' width='' textAlign="Right"></e-column>
                    <e-column field='description' headerText='Komentar' clipMode='EllipsisWithTooltip' width='200' allowFiltering='false' allowSorting='false' filterSettings='false'></e-column>
                    <e-column field='created_by' headerText='Napravio zahtev' width='' textAlign="Left" :visible="false"></e-column>
                    <e-column field='modified_by' headerText='Izmenio zahtev' width='' textAlign="Left" :visible="false"></e-column>
                    <e-column field='updated_at' headerText='Vreme izmene' width='' textAlign="Left" :visible="false"></e-column>
                </e-columns>

                <e-aggregates>
                    <e-aggregate>
                        <e-columns>
                            <e-column type="Sum" field="total"></e-column>
                        </e-columns>
                    </e-aggregate>
                </e-aggregates>
            </ejs-grid> 
        </div>
    </div>
</template>

<script>
    import ModalNewVenhicleService from '@/components/modals/transport/ModalNewVenhicleService.vue';
    import TransportService from '@/service/TransportService.js'
    import ModalConfirm from '@/components/modals/ModalConfirm.vue';
    import UtilService from '@/service/UtilService.js';

    import {mapState} from 'vuex'
    import { Modal } from 'bootstrap';
    import RowIndexTemplate from '@/components/grid-templates/RowIndex.vue';

    import { Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit, Resize, Aggregate, DetailRow } from "@syncfusion/ej2-vue-grids";

    export default {
        components: {ModalNewVenhicleService, ModalConfirm,},

        provide: {
            grid: [Sort, Page, ColumnMenu, Filter, Group, Toolbar, Edit, Resize, Aggregate, DetailRow ]
        },
        
        props: {
            transportVehicle: {
                type: Object,
                default: null,
            }
        },

        data() {
            return {

                filterSettings: { type: "Excel" },
                pageSettings: { pageCount: 5, pageSize: 20 },
                editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true },
                toolbarAdmin: [{ text: 'Dodaj', tooltipText: 'Dodaj', prefixIcon: 'e-add', id: 'addService' }, { text: 'Izmeni', tooltipText: 'Izmeni', prefixIcon: 'e-edit', id: 'editService'}, { text: 'Obriši', tooltipText: 'Obriši', prefixIcon: 'e-delete', id: 'deleteService'}],
                toolbarEmp: [],

                childItemsGrid: {
                    dataSource: [{"id":5,"name":"ST1","price":"13.00","service_id":5},{"id":3,"name":"Stavka 2","price":"123123.00","service_id":5}],
                    queryString: "service_id",
                    columns: [
                    {
                        field: "id",
                        headerText: "ID",
                        textAlign: "Right",
                        width: 120
                    },
                    { field: "name", headerText: "Stavka", width: 120 },
                    { field: "price", headerText: "Cena", width: 120 },
                    ],
                },

                rowIndexTemplate() {
                        return {
                            template: {
                                extends: RowIndexTemplate,
                            }
                        }

                },

                editedService: null,
                // vehicleService: null,
                serviceModal: null,

                confirmModal: null,
                confirmFunction: null,
                confirmTitle: "Potvrda brisanja naloga za servis",
                confirmMessage: "Da li ste sigurni da želite da obrišete informacije o serivusu"
            }
        },

        computed: {
            ...mapState([
                'user',
                'vehicleService'
            ]),

            toolbar() {
                if (this.user.role == "ADMIN") return this.toolbarAdmin;
                return null;
            },
        },

        methods: {
            formatDateTime : UtilService.formatDateTime,


            dateTimeAccessor(field, data) {
                return data[field] ? this.formatDateTime(data[field]) : '/';
            },


            confirmModalService() {
                this.serviceModal.hide();
            },

            async loadService() {
                let data = {
                    vehicle: this.transportVehicle.id,
                }
                this.$store.dispatch('loadAllVehicleService', data);

            },

            absenceDBClick(event) {
                if (this.user.role === 'ADMIN')
                    this.editService(event.rowData);
            },


            showAddServiceModal() {
                this.serviceModal.show();
            },

            async deleteService() {

                let data = {
                    id: this.editedService.id,
                }
                await TransportService.deleteVehicleService(data).then(() => {
                    this.loadService();
                }).catch((error) => {
                    console.log(error);
                })
            },

            editService(selected) {
                console.log(selected);
                    this.editedService = selected;

                    this.showAddServiceModal();
            },



            getItemsData() {
                let data = [];

                this.vehicleService.forEach(service => {
                    service.service_id = service.id;
                    service.items_obj.forEach(item => {
                        item.service_id = service.id;
                        data.push(item);
                    });
                });
                return data;
            },

            dataBound() {

                let scheduleObj = this.$refs.vehicleServiceGrid.ej2Instances; 

                scheduleObj.childGrid.dataSource = this.getItemsData();

                // this.scheduleObj.detailRowModule.expand(1); // initially 2nd chid Grid will expand.

            },

            clickHandler(args) {
                if (args.item.id === 'addService') {
                    this.showAddServiceModal()
                }

                if (args.item.id === "editService"){
                    let selected = (this.$refs.vehicleServiceGrid.ej2Instances.getSelectedRecords());
                    if (selected.length > 0) selected = selected[0];
                    else return;

                    this.editService(selected);
                }

                if (args.item.id === "deleteService"){
                    let selected = (this.$refs.vehicleServiceGrid.ej2Instances.getSelectedRecords());
                    if (selected.length > 0) selected = selected[0];
                    else return;
                    this.editedService = selected;

                    this.confirmModal.show();
                }
            },

            loadServiceItemNames() {
                this.$store.dispatch('loadServiceItemNames');
            },

        },



        created() {
            this.loadService()
            this.loadServiceItemNames();
            this.confirmFunction = this.deleteService;
        },

        mounted() {
            this.serviceModal = new Modal(document.getElementById('modal-new-service'));
            this.confirmModal = new Modal(document.getElementById('confirmModalService'));
        },
    }

</script>

<style>

</style>