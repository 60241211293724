<template>

        <div class="modal fade" :id="inputId" tabindex="-1" :aria-labelledby="inputId + 'Label'" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">
                        Sipanje goriva...
                        </h5>
                        <button type="button" class="btn-close-icon" data-bs-dismiss="modal" aria-label="Close">
                            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M0.340334 10.4655C0.181826 10.6241 0.0927763 10.839 0.0927763 11.0632C0.0927763 11.2874 0.181826 11.5024 0.340334 11.6609C0.498841 11.8194 0.713825 11.9084 0.93799 11.9084C1.16215 11.9084 1.37714 11.8194 1.53565 11.6609L5.99979 7.19531L10.4653 11.6595C10.6238 11.818 10.8388 11.907 11.063 11.907C11.2872 11.907 11.5021 11.818 11.6606 11.6595C11.8192 11.5009 11.9082 11.286 11.9082 11.0618C11.9082 10.8376 11.8192 10.6227 11.6606 10.4641L7.1951 6L11.6592 1.53445C11.8177 1.37595 11.9068 1.16096 11.9068 0.936798C11.9068 0.712633 11.8177 0.49765 11.6592 0.339142C11.5007 0.180633 11.2857 0.0915844 11.0616 0.0915844C10.8374 0.0915844 10.6224 0.180633 10.4639 0.339142L5.99979 4.80469L1.53424 0.338439C1.37573 0.17993 1.16075 0.0908813 0.936584 0.0908813C0.71242 0.0908814 0.497437 0.17993 0.338928 0.338439C0.18042 0.496947 0.0913706 0.71193 0.0913706 0.936095C0.0913706 1.16026 0.18042 1.37524 0.338928 1.53375L4.80447 6L0.340334 10.4655Z" fill="white"/>
                            </svg>
                        </button>
                    </div>
                    <div class="modal-body">

                        <form id="new-vehicle-fuel-form">
                            
                            <div class="d-flex">

                                <div class="field col-6">
                                    <label class="label" for="fiel-liters">Kolicina</label>
                                    <input class="input form-control" type="number" step=".01" placeholder="Koliko ste natočili" id="fiel-liters" v-model="vehicleFuelForm.liters" required />
                                    <div class="invalid-feedback">
                                        Količina u litrima je obavezno polje.
                                    </div>
                                    <span class="suffix">l</span>

                                </div>
                                <div class="field col-6">
                                    <label class="label" for="fiel-total">Cena</label>
                                    <input class="input form-control" type="number" step=".01" id="fiel-total" v-model="vehicleFuelForm.total" placeholder="Ukupno" required />
                                    <span class="suffix">RSD</span>
                                    <div class="invalid-feedback">
                                        Ukupna cena je obavezno polje.
                                    </div>

                                </div>
                            </div>
                            <div>
                                <div class="field col-12">
                                    <label class="label" for="text">Kilometraža ({{ minTotalDistance }}km
                                        <template v-if="vehicleFuelForm.total_distance - minTotalDistance > 0">
                                            + {{vehicleFuelForm.total_distance - minTotalDistance}}km
                                        </template>)
                                    </label>
                                    <input class="input form-control" type="number" :class="{'retroactive-fuel': minTotalDistance >= vehicleFuelForm.total_distance}" id="fiel-total-distance" v-model="vehicleFuelForm.total_distance" min="0" required placeholder="Trenutna kilometraža vozila" />
                                    <span class="suffix">km</span>
                                    <div class="invalid-feedback">
                                        Kilometraža je obavezno polje.
                                    </div>

                                </div>
                            </div>

                            <div class="d-flex px-2 py-4 w-100 btn-separate-group" role="group" aria-label="Basic radio toggle button group">
                                <div class="col-6 p-1">

                                    <input type="radio" class="btn-check" name="btnradio" id="btnradio1" autocomplete="off" v-model="vehicleFuelForm.payment_option" value="CARD" required>
                                    <label class="btn form-control w-100 btn-outline-primary d-flex justify-content-between p-3 px-4 col-6" for="btnradio1">
                                        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M21.8225 4.63147e-06C21.561 0.0104662 21.3086 0.0575431 21.0458 0.122928L3.269 4.454C1.16623 4.96924 -0.149311 7.09031 0.367227 9.19439L2.85969 19.3709C3.01073 19.977 3.3048 20.5382 3.7173 21.0073C4.12979 21.4765 4.64869 21.8399 5.23054 22.0673V19.6154C5.23054 16.0101 8.16369 13.0769 11.769 13.0769H28.2381L25.7862 2.98285C25.5693 2.10541 25.0565 1.3296 24.3342 0.786231C23.6119 0.242861 22.7257 -0.0348224 21.8225 4.63147e-06ZM23.4153 5.64008L24.4785 10.0941L20.065 11.1559L18.9613 6.74247L23.4153 5.64008ZM11.769 15.6923C9.60346 15.6923 7.84592 17.4499 7.84592 19.6154V30.0769C7.84592 32.2425 9.60346 34 11.769 34H30.0767C32.2422 34 33.9998 32.2425 33.9998 30.0769V19.6154C33.9998 17.4499 32.2422 15.6923 30.0767 15.6923H11.769ZM11.769 17.7768H30.0767C31.0849 17.7768 31.9153 18.6072 31.9153 19.6154V20.9231H9.93038V19.6154C9.93038 18.6072 10.7608 17.7768 11.769 17.7768ZM9.93038 24.8462H31.9153V30.0769C31.9153 31.0852 31.0849 31.9155 30.0767 31.9155H11.769C11.2822 31.9128 10.8161 31.7182 10.4719 31.374C10.1277 31.0298 9.93313 30.5637 9.93038 30.0769V24.8462Z"/>
                                        </svg>
                                        <span class="vertical-center">Kartica</span>
                                    </label>
                                    <div class="invalid-feedback">
                                        Metod plaćanja je obavezno polje.
                                    </div>
                                </div>

                                <div class="col-6 p-1">

                                    <input type="radio" class="btn-check" name="btnradio" id="btnradio2" autocomplete="off" v-model="vehicleFuelForm.payment_option" value="CACHE" required>
                                    <label class="btn form-control w-100 btn-outline-primary d-flex justify-content-between p-3 px-4  col-6" for="btnradio2">
                                        <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.5 18.75C15.1076 18.75 13.7723 19.3031 12.7877 20.2877C11.8031 21.2723 11.25 22.6076 11.25 24C11.25 25.3924 11.8031 26.7277 12.7877 27.7123C13.7723 28.6969 15.1076 29.25 16.5 29.25C17.8924 29.25 19.2277 28.6969 20.2123 27.7123C21.1969 26.7277 21.75 25.3924 21.75 24C21.75 22.6076 21.1969 21.2723 20.2123 20.2877C19.2277 19.3031 17.8924 18.75 16.5 18.75ZM14.25 24C14.25 23.4033 14.4871 22.831 14.909 22.409C15.331 21.9871 15.9033 21.75 16.5 21.75C17.0967 21.75 17.669 21.9871 18.091 22.409C18.5129 22.831 18.75 23.4033 18.75 24C18.75 24.5967 18.5129 25.169 18.091 25.591C17.669 26.0129 17.0967 26.25 16.5 26.25C15.9033 26.25 15.331 26.0129 14.909 25.591C14.4871 25.169 14.25 24.5967 14.25 24Z"/>
                                            <path d="M24.789 7.67399L20.0205 0.988495L2.487 14.9955L1.515 14.985V15H0.75V33H32.25V15H30.807L27.936 6.60149L24.789 7.67399ZM27.6375 15H12.5955L23.799 11.181L26.082 10.4505L27.6375 15ZM21.825 8.68499L10.26 12.627L19.419 5.30999L21.825 8.68499ZM3.75 27.2535V20.7435C4.38326 20.52 4.95848 20.1576 5.43346 19.6829C5.90844 19.2081 6.27116 18.6331 6.495 18H26.505C26.7287 18.6334 27.0913 19.2087 27.5663 19.6837C28.0413 20.1587 28.6166 20.5213 29.25 20.745V27.255C28.6166 27.4786 28.0413 27.8413 27.5663 28.3163C27.0913 28.7913 26.7287 29.3666 26.505 30H6.498C6.27328 29.3665 5.90995 28.7912 5.43453 28.316C4.95911 27.8409 4.3836 27.4779 3.75 27.2535Z"/>
                                        </svg>
                                        
                                        <span class="vertical-center">Keš</span>
                                    </label>
                                    
                                </div>

                            </div>

                            <div class="d-flex flex-wrap p-2 w-100 fuel-group btn-separate-group" role="group" aria-label="Basic radio toggle button group">
                                <div class="col-4 p-1" v-for="gasStation in gasStations" :key="gasStation.id">

                                    <input type="radio" class="btn-check" name="fuel" :id="'fuel-' + gasStation.id" autocomplete="off" :value="gasStation.name" v-model="vehicleFuelForm.pump" required>
                                    <label class="btn w-100 btn-outline-primary d-flex justify-content-between align-items-center flex-column p-1 px-2 col-6" :for="'fuel-' + gasStation.id">
                                        <img :src="getFuelImage(gasStation.name)" alt="">
                                        <span class="vertical-center">{{ gasStation.name }}</span>
                                    </label>
                                    <div class="invalid-feedback">
                                        Odaberi pumpu
                                    </div>
                                </div>
                            </div>

                            <div class="show-more">

                                <button v-if="!showMore" @click="showMore = true" class="btn">Ostala polja <svg width="10" height="7" viewBox="0 0 10 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M5.71295 6.09265C5.52545 6.27992 5.27128 6.3851 5.00628 6.3851C4.74128 6.3851 4.48711 6.27992 4.29961 6.09265L0.527615 2.32198C0.340107 2.13439 0.234801 1.87999 0.234863 1.61475C0.234926 1.34951 0.340351 1.09516 0.527948 0.90765C0.715544 0.720142 0.969945 0.614836 1.23518 0.614899C1.50042 0.614961 1.75477 0.720387 1.94228 0.907983L5.00628 3.97198L8.07028 0.907983C8.2588 0.725735 8.51135 0.62482 8.77355 0.626973C9.03575 0.629127 9.28661 0.734178 9.47211 0.919499C9.65761 1.10482 9.76289 1.35558 9.7653 1.61778C9.7677 1.87998 9.66702 2.13263 9.48495 2.32132L5.71361 6.09332L5.71295 6.09265Z" fill="#1B1B1B"/>
                                    </svg>
                                </button>
                                <div v-else class="w-100 px-2">

                                    <div class="mb-3 d-flex flex-wrap">
                                        <div class="form-floating col-7">
                                            <input type="date" class="form-control" id="fiel-tanked-at" v-model="vehicleFuelForm.tanked_at_date" placeholder="Datum" @change="changedTankedDateTime" required>
                                            <label for="fiel-liters">Datum sipanja</label>
                                        </div>
                                        <div class="form-floating col-5">
                                            <input type="time" class="form-control" id="fiel-tanked-at" v-model="vehicleFuelForm.tanked_at_time" placeholder="Vreme sipanja" @change="changedTankedDateTime" required>
                                            <label for="fiel-liters">Vreme sipanja</label>
                                        </div>
                                        <div class="invalid-feedback">
                                            Vreme sipanja je obavezno polje.
                                        </div>
                                    </div>

                                    <div class="mb-3 form-floating">
                                        <input type="text" class="form-control" id="field-receipt-id" v-model="vehicleFuelForm.receipt_id" placeholder="ABCDEFGH-ABCDEFGH-12345">
                                        <label for="fiel-liters">Broj računa - (ABCDEFGH-ABCDEFGH-12345)</label>
                                        <div class="invalid-feedback">
                                            Link računa je obavezno polje.
                                        </div>
                                    </div>


                                    <div class="mb-3 form-floating">
                                        <input type="text" class="form-control" id="field-receipt-url" v-model="vehicleFuelForm.receipt_url" placeholder="Skenirajte račun i nalepite link">
                                        <label for="fiel-liters">Link e-računa QR-kod</label>
                                        <div class="invalid-feedback">
                                            Vreme sipanja je obavezno polje.
                                        </div>
                                    </div>

                                    <div class="mb-3 form-floating">
                                        <input type="text" class="form-control" id="woCode" v-model="vehicleFuelForm.description" placeholder="Komentar">
                                        <label for="woCode">Komentar</label>
                                    </div>

                                </div>

                            </div>                            
                            
                        </form>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Izlaz</button>
                        <button type="button" class="btn btn-primary" @click="createWorkOrder" >{{vehicleFuel?'Izmeni':'Dodaj'}}</button>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
    import {mapState} from 'vuex'
    import TransportService from '@/service/TransportService.js'
    import UtilService from '@/service/UtilService.js';

    export default {

        props: {
            inputId: {
                type: String,
                default: "",
            },

            vehicleFuel: {
                type: Object,
                default: null,
            },

            transportVehicle: {
                type: Object,
                default: null,
            },

            dateRange: {
                type: Array,
                default: function () { 
                    return [
                        UtilService.getMoment().add(-90, 'day').toDate(),
                        UtilService.getMoment().toDate()
                    ]
                },
            }
        },

        data() {
            return {
                vehicleFuelForm: {},
                showMore: false
            }
        },
        
        computed: {
            ...mapState([
                'user',
                'gasStations',
                'vehicleFuels'
                
            ]),

            minTotalDistance() {
                if (this.vehicleFuel) return 0;

                if (this.vehicleFuels?.length > 0) {
                    if (this.vehicleFuels[0].total_distance > 0) return this.vehicleFuels[0].total_distance
                }

                return 0;

            }
            
        },


        watch:{
            vehicleFuel(){
                this.updateProp();
            }
        },

        created() {
            this.updateProp();
            this.loadGasStations();
        },

        methods: {
            updateProp() {
                if (this.vehicleFuel) {
                    this.vehicleFuelForm = {}
                    this.vehicleFuelForm.id = this.vehicleFuel.id;
                    this.vehicleFuelForm.liters = this.vehicleFuel.liters;
                    this.vehicleFuelForm.total = this.vehicleFuel.total;
                    this.vehicleFuelForm.fuel_type = this.vehicleFuel.fuel_type;
                    this.vehicleFuelForm.payment_option = this.vehicleFuel.payment_option;
                    this.vehicleFuelForm.pump = this.vehicleFuel.pump;
                    this.vehicleFuelForm.driver = this.vehicleFuel.driver;
                    this.vehicleFuelForm.vehicle = this.transportVehicle.id;
                    this.vehicleFuelForm.total_distance = this.vehicleFuel.total_distance;
                    this.vehicleFuelForm.tanked_at = this.vehicleFuel.tanked_at;
                    this.vehicleFuelForm.tanked_at_date = this.vehicleFuel.tanked_at.slice(0,10);
                    this.vehicleFuelForm.tanked_at_time = this.vehicleFuel.tanked_at.slice(11,16);
                    this.vehicleFuelForm.receipt_id = this.vehicleFuel.receipt_id;
                    this.vehicleFuelForm.receipt_url = this.vehicleFuel.receipt_url;
                    this.vehicleFuelForm.comment = this.vehicleFuel.comment;
                } else {
                    let currentTime = UtilService.getMoment().format()
                    console.log("WORKS");
                    
                    this.vehicleFuelForm = {}
                    this.vehicleFuelForm.vehicle = this.transportVehicle.id;
                    this.vehicleFuelForm.driver = this.user.id;
                    this.vehicleFuelForm.fuel_type = 'DIESEL';
                    this.vehicleFuelForm.payment_option = 'CARD';
                    this.vehicleFuelForm.tanked_at = currentTime;
                    this.vehicleFuelForm.tanked_at_date = currentTime.slice(0,10);
                    this.vehicleFuelForm.tanked_at_time = currentTime.slice(11,16);
                }
                console.log(this.vehicleFuelForm);
            },

            async loadGasStations() {
                await this.$store.dispatch('loadAllGasStations').catch((error) => {
                    this.error = error;
                });
            },

            async createWorkOrder() {
                let form = document.getElementById("new-vehicle-fuel-form");
                
                form.classList.add('was-validated');

                console.log(form);
                console.log(form.checkValidity());

                if (!form.checkValidity())
                    return;

                if (this.vehicleFuel) {
                    await this.updateFuel();
                }
                else {
                    await this.$store.dispatch('createVehicleFuel', this.vehicleFuelForm)
                    .then(() => {

                        this.toast("Sipanje goriva uspešno.");
                        this.$emit('confirm');

                    })
                    .catch((error) => alert(error.message));    
                }

                this.loadAllVehicleFuel();

            },

            loadAllVehicleFuel() {
                let start = UtilService.dateToUTC(this.dateRange[0]).toISOString().slice(0,10);
                let end = UtilService.dateToUTC(this.dateRange[1]).toISOString().slice(0,10);

                let data = {
                    vehicle: this.transportVehicle.id,

                    params: {
                        start_date: start,
                        end_date: end,
                    }
                }

                this.$store.dispatch('loadAllVehicleFuel', data);

            },

            changedTankedDateTime() {
                if (!this.vehicleFuelForm.tanked_at_date) this.$set(this.vehicleFuelForm, "tanked_at_date", new Date().toISOString().slice(0,10))
                if (!this.vehicleFuelForm.tanked_at_time) this.$set(this.vehicleFuelForm, "tanked_at_time", new Date().toISOString().slice(11,16))
                this.vehicleFuelForm.tanked_at = this.vehicleFuelForm.tanked_at_date + "T" + this.vehicleFuelForm.tanked_at_time
            },

            async updateFuel() {
                    await TransportService.updateVehicleFuel(this.vehicleFuelForm)
                    .then(() => {
                        this.toast("Uspešna izmena.");
                        this.$emit('confirm');
                    })
                    .catch((error) => alert(error.message))
            },

            toast(message, type) {
                this.$toasted.show(message, { 
                    type: type,
                    theme: "toasted-primary", 
                    position: "top-center", 
                    duration : 3000
                });
            },

            getFuelImage(name) {
                name = name.toLowerCase()
                if (name == "nis") {
                    return require('@/assets/fuel/nis.png')
                }if (name == "mol") {
                    return require('@/assets/fuel/mol.png')
                }if (name == "omv") {
                    return require('@/assets/fuel/omv.png')
                }if (name == "lukoil") {
                    return require('@/assets/fuel/lukoil.png')
                }if (name == "art petrol") {
                    return require('@/assets/fuel/art.png')
                }

                return require('@/assets/fuel/fuel.png')
            }


        },

    }
</script>

<style scoped>
    .modal-header {
        background-color: #4368C4;
        color: white;
        border-bottom: 0;

    }

    .modal-title {
        font-size: 1.2em;
    }

    .btn-close {
        color: white;
    }

    #new-vehicle-fuel-form .retroactive-fuel {
        background: rgb(255, 250, 94);
    }

    .btn-close-icon {
        background: none;
        border: none;
    }



    .modal-body {
        padding: 0
    }




    .field {
        display: flex;
        flex-direction: column;
        position: relative;
    }

    .field .label {
        position: absolute;
        margin: 0 0 0 1em;
        color: #1b1b1b;
        top: 0.7em;
        font-weight: bold;
    }

    .field .input {
        padding: 2.5em 3em 1.5em 1em;
        width: 100%;
        font-size: 1.2em;
        border: 1px solid #A7AFB7;
        border: 0.9px solid #A7AFB7;

    }

    .field .suffix {
        right: 0;
        position: absolute;
        color: #1b1b1b;
        top: 3em;
        right: 1em;
    }

    .btn-separate-group {
        width: 100%;
        padding: 1.5em 1em
    }

    .btn-separate-group label:hover svg, .btn-separate-group .btn-check:checked + label svg  {
        fill: white
    }

    .btn-separate-group label:hover, .btn-separate-group .btn-check:checked + label  {
        color: white;
        background-color: #4368C4;
    }

    .btn-separate-group label svg {
        fill: #1b1b1b
    }

    .btn-separate-group label {
        background-color: #F7F7F7;
        border-color: #A7AFB7;
        color: #1b1b1b;
        border-radius: 0.8em;
        
    }

    .btn-separate-group label span {
        font-size: 1.2em;
    }



    .vertical-center {
        display: flex;
        align-items: center;
    }

    .btn-separate-group {
        border: 1px solid #A7AFB7;
        border: 0.9px solid #A7AFB7;
    }

    .fuel-group label {
        height: 7em;
        font-size: 1em;
        font-weight: bold;
        padding-bottom: 1em;
    }

    .fuel-group label img {
        object-fit: contain;
        max-width: 100%;
        max-height: 4em;
    }



    .show-more {
        border: 1px solid #A7AFB7;
        border: 0.9px solid #A7AFB7;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 2em 0;
        
    }
    
    .show-more .btn {
        background-color: #F7F7F7;
        border: 1px solid #A7AFB7;
        border-radius: 0.8em;
    }

    .field .invalid-feedback {
        position: absolute;
        bottom: 5px;
        margin-left: 1em;
    }

    .was-validated .form-control:invalid, .form-control.is-invalid, .was-validated .form-control:valid, .form-control.is-valid {
        background-position: right calc(0.375em + 0.1875rem) top 10px;
    }
</style>