<template>
    <div class="vehicle-trip-container">
        <!-- <modal-new-venhicle-trip @confirm="confirmModalService" inputId="modal-new-trip" :transportVehicle="transportVehicle" :vehicleService="editedService"  />
        <modal-confirm @confirm="confirmFunction" inputId="confirmModalService" :title="confirmTitle" :message="confirmMessage"/> -->
        <div v-if="vehicleTrips">
            <ejs-schedule 
                height='550px'
                ref='tripSchedule'
                :dateFormat='dateFormat'
                :timeFormat='timeFormat'
                :firstDayOfWeek='1'
                :selectedDate='selectedDate'
                :dataBinding='dataBindEvent'
                :eventSettings='eventSettings'>

                
            </ejs-schedule>
        </div>
    </div>
</template>

<script>
    // import ModalNewVenhicleService from '@/components/modals/transport/ModalNewVenhicleService.vue';
    import TransportService from '@/service/TransportService.js'
    // import ModalConfirm from '@/components/modals/ModalConfirm.vue';

    import {mapState} from 'vuex'
    import { Day, Week, WorkWeek, Month, Agenda } from '@syncfusion/ej2-vue-schedule';
    // import { Modal } from 'bootstrap';


    export default {
        // components: {ModalNewVenhicleService, ModalConfirm,},

        provide: {
              schedule: [Day, Week, WorkWeek, Month, Agenda]
        },

        data() {
            return {
                selectedDate: new Date(),
                eventSettings: {
                    dataSource: this.vehicleTrips,
                    allowEditing: false,
                    allowAdding: false,
                    fields: {
                        id: 'id',
                        subject: { name: 'name' },
                        isAllDay: { name: 'deleted' },
                        startTime: { name: 'start_time' },
                        endTime: { name: 'end_time' }
                    }
                },

                dateFormat: "dd.MM.yyyy.",
                timeFormat: "HH:mm",


                editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true },
                toolbarAdmin: [{ text: 'Dodaj', tooltipText: 'Dodaj', prefixIcon: 'e-add', id: 'addService' }, { text: 'Izmeni', tooltipText: 'Izmeni', prefixIcon: 'e-edit', id: 'editService'}, { text: 'Obriši', tooltipText: 'Obriši', prefixIcon: 'e-delete', id: 'deleteService'}],
                toolbarEmp: [{ text: 'Zahtev za odsustvo', tooltipText: 'Dodaj', prefixIcon: 'e-add', id: 'addService' }],


                editedService: null,
                // vehicleService: null,
                serviceModal: null,

                confirmModal: null,
                confirmFunction: null,
                confirmTitle: "Potvrda brisanja naloga za servis",
                confirmMessage: "Da li ste sigurni da želite da obrišete informacije o serivusu"
            }
        },

        computed: {
            ...mapState([
                'user',
                'transportVehicle',
                'vehicleTrips'
            ]),

            // toolbar() {
            //     if (this.user.role == "ADMIN") return this.toolbarAdmin;
            //     if (this.myProfile) return this.toolbarEmp;
            //     return [];
            // }
        },

        methods: {
            confirmModalService() {
                this.serviceModal.hide();
            },

            async loadTrips() {
                let data = {
                    vehicle: this.transportVehicle.id,
                }
                await this.$store.dispatch('loadAllVehicleTrips', data).then(() => {
                    this.scheudleRefreshData();
                });

                

            },

            // absenceDBClick(event) {
            //     this.editService(event.rowData);
            // },


            // showAddServiceModal() {
            //     this.serviceModal.show();
            // },

            async deleteService() {

                let data = {
                    id: this.editedService.id,
                }
                await TransportService.deleteVehicleTrip(data).then(() => {
                    this.loadTrips();
                }).catch((error) => {
                    console.log(error);
                })
                
            },

            // editService(selected) {
            //     console.log(selected);
            //         this.editedService = selected;

            //         this.showAddServiceModal();
            // },




            dataBindEvent() {

            },

            componentRefresh() {
                this.scheudleRefreshData();
            },

            scheudleRefreshData() {
                console.log("refresh");
                let scheduleObj = this.$refs.tripSchedule.ej2Instances; 
                scheduleObj.eventSettings.dataSource = this.vehicleTrips;
                scheduleObj.refresh();
                scheduleObj.scrollTo("07:00");

            }


        },



        created() {
            this.confirmFunction = this.deleteService;
        },

        mounted() {
            this.loadTrips()

            // this.serviceModal = new Modal(document.getElementById('modal-new-trip'));
            // this.confirmModal = new Modal(document.getElementById('confirmModalService'));
        },
    }

</script>

<style>

</style>