<template>

    <div v-if="transportVehicle">
<!-- 
        <div class="title-header">
            <div class="row px-3">
                <div class="col-sm-12">
                    <h2>
                        <span class="badge bg-primary">{{transportVehicle.plate_code}}</span>
                        {{transportVehicle.name}}              
                    </h2>
                </div>
            </div>
        </div> -->

        <div class="row mt-3">
            <div class="d-flex flex-wrap">
                <div class="flex-shrink-0">
                    <h1 class="me-3"><font-awesome-icon icon="car" size="3x"/></h1>
                </div>

                <div class="color-dot" :style="'background: ' + transportVehicle.color_hex"></div>

                <div class="basic-info">
                    <h2>{{transportVehicle.name}} </h2>
                    <div class="plates">
                        <span class="blue-prefix">SRB</span>
                        <div class="code">
                            <h3>{{transportVehicle.plate_code}}</h3>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-4">
            </div>
            <div class="col-md-8">
            </div>
        </div>

        <div class="row mt-3">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home-vehicle-container" type="button" role="tab" aria-controls="home-vehicle-container" aria-selected="true" @click="changeTab('vehicleInfo', 'home-vehicle-container')">Informacije</button>
                </li>
                <li v-if="user.role === 'ADMIN'" class="nav-item" role="presentation">
                    <button class="nav-link" id="trip-tab" data-bs-toggle="tab" data-bs-target="#trip-vehicle-container" type="button" role="tab" aria-controls="trip-vehicle-container" aria-selected="true" @click="changeTab('vehicleTrip', 'trip-vehicle-container')">Putni nalozi</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="fuel-tab" data-bs-toggle="tab" data-bs-target="#fuel-vehicle-container" type="button" role="tab" aria-controls="fuel-vehicle-container" aria-selected="false">Gorivo</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="service-tab" data-bs-toggle="tab" data-bs-target="#service-vehicle-container" type="button" role="tab" aria-controls="service-vehicle-container" aria-selected="false">Servis</button>
                </li>
            </ul>
            <div v-if="loaded" class="tab-content mt-3" id="myTabContent">
                <div class="tab-pane fade show active" id="home-vehicle-container" role="tabpanel" aria-labelledby="home-tab">
                    <vehicle-info ref="vehicleInfo" :transportVehicle="transportVehicle" />
                </div>
                <div v-if="user.role === 'ADMIN'" class="tab-pane fade show" id="trip-vehicle-container" role="tabpanel" aria-labelledby="trip-tab">
                    <vehicle-trip ref="vehicleTrip" />
                </div>
                <div class="tab-pane fade" id="fuel-vehicle-container" role="tabpanel" aria-labelledby="profile-tab">
                    <vehicle-fuel ref="vehicleFuel" :transportVehicle="transportVehicle" />
                </div>
                <div class="tab-pane fade" id="service-vehicle-container" role="tabpanel" aria-labelledby="contact-tab">
                    <vehicle-service ref="vehicleService" :transportVehicle="transportVehicle" />
                </div>
                
            </div>

        </div>

    </div>
</template>

<script>
import {mapState} from 'vuex'
// import ModalNewWorkOrder from '@/components/modals/ModalNewWorkOrder.vue';
import VehicleInfo from '@/components/transport/VehicleInfo.vue';
import VehicleTrip from '@/components/transport/VehicleTrip.vue';
import VehicleFuel from '@/components/transport/VehicleFuel.vue';
import VehicleService from '@/components/transport/VehicleService.vue';

export default {
    components: { VehicleInfo, VehicleFuel, VehicleService, VehicleTrip, },

    data: function () {
        return {
            loaded: false,
        }
    },
    computed: {
        ...mapState([
            'user',
            'transportVehicle'
        ]),
        
        transportVehicleId() {
            return parseInt(this.$route.params.tvId);
        }
    },

    methods: {
        
        async loadTransportVehicle(id) {
            await this.$store.dispatch('loadTransportVehicle', {id});
            this.loaded = true;
            this.changeTab('vehicleInfo', 'home-vehicle-container')
        },

        changeTab(ref, id) {
            document.getElementById(id).classList.add("active");
            document.getElementById(id).classList.add("fade");
            this.$refs?.[ref].componentRefresh?.();
        }


    },

    created() {
        this.loadTransportVehicle(this.transportVehicleId);
    },

  // breadcrumb () {
  //   return {
  //     label: "label",
  //     parent: "Home"
  //   }
  // },
}
</script>

<style scoped>0
    .container {
        min-height: 74vh;
    }

    .table-info {
        font-size: 1.2em;
    }
    .table-info td {
        padding: 5px 10px;
    }

    .title-header{
        padding: 1em 0;
        background: #299be4;
        color: #fff;
        margin: 10px 0px 0;
    }

    .title-header h2{
        margin: 5px 0 0;
        font-size: 1.8em;
    }

    .title-header .btn {
        color: #566787;
        float: right;
        font-size: 13px;
        background: #fff;
        border: none;
        min-width: 50px;
        border-radius: 2px;
        border: none;
        outline: none !important;
        /* margin-left: 10px; */
    }

    .title-header .btn:hover, .title-header .btn:focus {
        color: #566787;
        background: #f2f2f2;
    }

    .title-header .btn .btn-title {
        margin-top: 20px;
    }

    .plates {
        height: 35px;
        outline: 3px solid black;
        border-radius: 3px;
        width: 150px;
    }

    .plates .blue-prefix {
        background: rgb(80, 80, 255);
        height: 100%;
        vertical-align: top;

        padding-top: 20px;
        display: inline-block;
        color: white;
        font-weight: 700;
        font-size: 0.5em;
        width: 20px;
        text-align: center;
    }

    .plates .code {
        font-size: 1.5em;
        display: inline-block;
        background: rgb(237, 237, 237);
        height: 100%;
        padding-top: 7px;
        font-weight: 700;
        width: 130px;
    }

    .plates .code h3 {
        font-weight: 700;
        font-size: 1em;
        margin-left: 10px;
    }


    .color-dot {
        width: 40px;
        height: 40px;
        border-radius: 100%;
        border: black 2px solid;
        margin-left: calc(2.5rem + 1.5vw);
        position: absolute;
    }

    .tab-content > .tab-pane  {
        display: block;
        overflow: hidden;
        height: 0;
    }


    .tab-content > .tab-pane.active  {
        overflow: initial;
        height: initial;
    }
    



</style>