<template>
    <div>
        <modal-new-transport-venhicle @confirm="confirmModalTransport" :transportVehicle="editTransportVehicle" inputId="modal-new-tv"  />
        <modal-confirm @confirm="confirmAction" inputId="confirmDeleteModal" title="Potvrda brisanja vozila" :message="confirmMessage"/>

        <div class="row" v-if="transportVehicle">
            <div class="table-responsive col-md-6">
                <table class="table mb-0">
                    <tbody>
                        <tr>
                            <th scope="row">Naziv</th>
                            <td>{{transportVehicle.name}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Tablice</th>
                            <td>{{transportVehicle.plate_code}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Broj sedišta</th>
                            <td>{{transportVehicle.capacity}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Registrovan do</th>
                            <td :class="{'warning-row': warnings.registration_end >= 0, 'danger-row': warnings.registration_end < 0}">
                                <i v-if="warnings.registration_end" class="fas fa-exclamation-triangle text-warning"/>
                                {{formatDate(transportVehicle.registration_end)}}
                                </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="table-responsive col-md-6">
                <table class="table">
                    <tbody>
                        <tr>
                            <th scope="row">Proizvođač</th>
                            <td>{{transportVehicle.make}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Model</th>
                            <td>{{transportVehicle.model}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Godina proizvodnje</th>
                            <td>{{transportVehicle.year}}</td>
                        </tr>
                        <tr>
                            <th scope="row">Boja</th>
                            <td>{{transportVehicle.color}} <span class="color-dot" :style="'background: ' + transportVehicle.color_hex"></span></td>
                        </tr>
                        <tr>
                            <th scope="row">Opis</th>
                            <td>{{transportVehicle.description}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>

        <div v-if="user.role === 'ADMIN'" class="btn btn-primary" @click="openEditModal">Izmena</div>
        <div v-if="user.role === 'ADMIN'" class="btn btn-danger" @click="deleteVehicleConfirm">Izbriši</div>



    </div>
</template>

<script>
    import ModalNewTransportVenhicle from '@/components/modals/ModalNewTransportVenhicle.vue';
    import TransportService from '@/service/TransportService.js'
    import UtilService from '@/service/UtilService.js'
    import {Modal} from 'bootstrap'
    import {mapState} from 'vuex'
    import ModalConfirm from '@/components/modals/ModalConfirm.vue';

    export default {

        components: { ModalNewTransportVenhicle, ModalConfirm},

        data () {
            return {
                editTransportVehicle: null,
                editModal: null,
                warnings: {registration_end:undefined},
                confirmMessage: "Da li ste sigurni da želite da obrišete radnika ?",
                confirmAction: this.deleteVehicle,
            }
        },

        // props: {
        //     transportVehicle: {
        //         type: Object,
        //         default: null,
        //     }
        // },

        computed: {
            ...mapState([
                'user',
                'transportVehicle',
            ]),
        },

        methods: {
            checkVehicleWarning: UtilService.checkVehicleWarning,
            formatDate: UtilService.formatDate,


            openEditModal() {
                this.editTransportVehicle = UtilService.deepClone(this.transportVehicle);
                this.editModal.show();
            },

            confirmModalTransport() {
                this.editModal.hide();
                this.$store.dispatch('loadTransportVehicle', {id: this.transportVehicle.id});

            },

            async deleteVehicle(){
                try {
                    await TransportService.deleteTransportVehicle(this.transportVehicle);
                    this.toast("Vozilo obrisano", "success");
                    this.$router.push({ name: 'transport-vehicles' })

                } catch {
                    this.toast("Neuspesno brisanje", "error");
                }
            },

            deleteVehicleConfirm() {
                this.confirmDeleteModal.show()
            },

            calculateWarnings() {
                this.warnings = this.checkVehicleWarning(this.transportVehicle);
                console.log(this.warnings);
            },

            componentRefresh() {
                this.calculateWarnings();

            },


            toast(message, type) {
                this.$toasted.show(message, { 
                    type: type,
                    theme: "toasted-primary", 
                    position: "top-center", 
                    duration : 3000
                });
            }
        },

        created() {

        },

        mounted() {
            this.editModal = new Modal(document.getElementById('modal-new-tv'));
            this.confirmDeleteModal = new Modal(document.getElementById('confirmDeleteModal'));

        }
    }
</script>

<style scoped>

    .table .color-dot {
        display: inline-block;
        box-shadow: black 0 0 2px;
        width: 1em;
        height: 1em;
        border-radius: 100%;
        position: absolute;
        margin-top: -5px;
        margin-left: 10px;
        height: 26px;
        width: 26px;
    }

    td.warning-row {
    background: rgb(255, 240, 30);
}

    td.danger-row {
        background: rgb(255, 79, 79);
    }
    td.danger-row, td.danger-row span {
        color: white;
        font-weight: bold;
    }
</style>